<template>
	<v-container
		fill-height
		fluid
		grid-list-xl
	>
		<v-layout
		>
			<v-skeleton-loader
				:loading="loading"
				transition="fade-transition"
				style="width: 100%"
				type="table"
			>
				<v-data-table
					:headers="headers"
					:items="customers"
					sort-by="first_name"
					class="elevation-1 col-12"
				>
					<template v-slot:top>
						<v-toolbar color="primary" style=" border-radius : 7px" class="white--text mb-3" >
							<v-toolbar-title>
								Customers
								<br/>
								<span
									class="subheading font-weight-bold caption"
								>
									List of all customers
								</span>
							</v-toolbar-title>
							<v-divider
								class="mx-4"
								inset
								vertical
							></v-divider>
							<v-spacer></v-spacer>
							<!-- ADD Customer  -->
							<!-- <v-dialog v-model="add_user_dialog" max-width="520px">
								<template v-slot:activator="{ on }">
										<v-btn rounded color="white" text v-on="on">
											<v-icon>mdi-account-plus</v-icon>
											<span class="ml-3">add customer</span>
										</v-btn>
									<v-btn color="primary" dark class="mb-2" v-on="on">New Item</v-btn>
								</template>
								<v-card>
									<v-card-title >
										<v-row justify="space-between">
											<span class="headline">{{ formTitle }}</span>
											<v-btn icon color="primary" class="mr-3" @click="add_user_dialog = false">
												<v-icon>mdi-close</v-icon>
											</v-btn>
										</v-row>
									</v-card-title>

									<v-card-text>
										<v-container>
											<v-row>
												<v-col cols="12">
													<v-text-field
														prepend-icon="mdi-account"
														filled
														v-model="editedItem.username"
														label="Username"></v-text-field>
												</v-col>
												<v-col cols="12" sm="6" >
													<v-text-field
														prepend-icon="mdi-account"
														filled
														v-model="editedItem.first_name"
														label="First Name"></v-text-field>
												</v-col>
												<v-col cols="12" sm="6">
													<v-text-field
														prepend-icon="mdi-account"
														filled
														v-model="editedItem.last_name"
														label="Last Name"></v-text-field>
												</v-col>
												<v-col cols="12">
													<v-text-field
														prepend-icon="mdi-phone"
														filled
														v-model="editedItem.phone"
														label="Phone Number"></v-text-field>
												</v-col>
												<v-col cols="12">
													<v-text-field
														prepend-icon="mdi-email"
														filled
														v-model="editedItem.email"
														label="Email"></v-text-field>
												</v-col>
												<template v-if="formTitle == 'New Customer'">
													<v-col cols="12" sm="6">
														<v-text-field
															prepend-icon="mdi-lock"
															filled
															v-model="editedItem.password"
															label="Password"></v-text-field>
													</v-col>
													<v-col cols="12" sm="6">
														<v-text-field
															prepend-icon="mdi-lock-reset"
															filled
															v-model="editedItem.confirm_password"
															label="Confirm Password"></v-text-field>
													</v-col>
													<v-col class="d-flex" cols="12" sm="6">
														<v-select
															prepend-icon="mdi-account-badge"
															filled
															v-model="editedItem.account_type"
															:items="account_types"
															label="Account Type"
														></v-select>
													</v-col>
													<v-col class="d-flex" cols="12" sm="6">
														<v-select
															prepend-icon="mdi-domain"
															filled
															v-model="editedItem.company"
															:items="companies"
															label="Company"
														></v-select>
													</v-col>
												</template>

											</v-row>
										</v-container>
									</v-card-text>


									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn rounded color="grey" text @click="close">Cancel</v-btn>
										<v-btn rounded color="primary" @click="save">Save</v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>


							<v-btn class="ml-7" rounded text color="white">
								<v-icon>mdi-plus-thick</v-icon>
								<span  class="ml-3"> Customer Roles</span>
							</v-btn> -->

						</v-toolbar>
					</template>
					<template v-slot:item.action="{ item }">
						<v-icon
							color="success"
							small
							class="mr-3"
							@click="editItem(item)"
						>
							edit
						</v-icon>
						<v-icon
							color="error"
							small
							@click="deleteItem(item)"
						>
							delete
						</v-icon>
					</template>
					<!-- <template v-slot:no-data>
						<v-btn color="primary" @click="initialize">Reset</v-btn>
					</template> -->
				</v-data-table>
			</v-skeleton-loader>
			<v-dialog
				v-model="deletedItem.show"
				width="500"
			>
				<v-card style="border-raduis: 32px">
					<v-card-title
						class="white--text red"
						primary-title
					>
						<v-row
							justify="space-between"
							align="start"
						>
							<p>Delete Customer</p>
							<v-btn icon color="white" @click="deletedItem.show = false">
								<v-icon >close</v-icon>
							</v-btn>
						</v-row>
					</v-card-title>

					<v-card-text class=" subtitle-1 pt-5">
						Deleted customers can not be retrived.
						Are you sure you want to delete the customer?
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="red"
							text
							@click="dialog = false"
						>
							Confirm
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-layout>
	</v-container>
</template>
<script>
export default {
    data: () => ({
		loading: true,
		add_user_dialog: false,
		add_user_role_dialog: false,
		customers: [
			{
				username: 'quasar241',
				first_name: 'Ernest',
				last_name: 'Hayford',
				email: 'quenginedev@gmail.com',
				phone: '+233506960917',
				account_type: 'admin',
				date_added: 'Some Date String'
			},
		],
		headers: [
        // {
        //   text: 'Dessert (100g serving)',
        //   align: 'left',
        //   sortable: false,
        //   value: 'name',
        // },
        // { text: 'Username', align: 'left', value: 'username' },
        { text: 'Firstname', align: 'left', value: 'first_name' },
        { text: 'Lastname', align: 'left', value: 'last_name' },
        // { text: 'Email', align: 'left', value: 'email' },
        { text: 'Phone No.', align: 'left', value: 'phone' },
        // { text: 'Account Type', align: 'left', value: 'account_type' },
				{ text: 'Date Added', align: 'left', value: 'date_added' },
				// { text: 'Actions', value: 'action', sortable: false },
			],
			account_types: ['Admin', 'Agency'],
			companies: ['AgroInova Ghana', 'Alex Farms'],
			editedIndex: -1,
			deletedItem: {
				show: false
		},
		editedItem: {

		},
		defaultItem: {

		},
    }),

    computed: {
		formTitle () {
			return this.editedIndex === -1 ? 'New Customer' : 'Edit Customer'
		},
    },

	watch: {
		add_user_dialog (val) {
        	val || this.close()
      	},
    },

    created () {
		this.initialize()
    },

    methods: {
		initialize () {
			this.$http.get('https://jsonplaceholder.typicode.com/users')
				.then(res=>{
					this.customers = res.data.map(customer =>{
						let name = customer.name.split(' ')
						return {
							...customer,
							first_name : name[0],
							last_name : name[1],
							company : customer.company.name,
							account_type: 'Agent',
							date_added: 'Wed Jan 22 2020 15:34:14'
						}
					})
					this.loading = false
				}).catch(err=>{
					console.error(err)
				})
		},

		editItem (item) {
			this.editedIndex = this.customers.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.add_user_dialog = true
		},

      deleteItem (item) {
        const index = this.customers.indexOf(item)
				this.deletedItem = { show: true, ...item }
				// && this.customers.splice(index, 1)
      },

      close () {
        this.add_user_dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.customers[this.editedIndex], this.editedItem)
        } else {
          this.customers.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>
